import {
  AppConfig,
  AppInsightsConfig,
  Auth0Config,
  ClientConfig,
  I18NexusConfig,
  SupportedBrowser,
} from '@indicina/swan-shared/interfaces/config/Settings';
import { AppUtils } from '@indicina/swan-shared/utils/AppUtils';

export class AppSettings {
  private static isInitialised = false;

  // Values are set from the 'client-config.json' file at runtime.
  static SWANbuild: string;
  static SWANversion: string;
  static app: AppConfig;
  static appInsights: AppInsightsConfig;
  static auth0: Auth0Config;
  static i18nexus: I18NexusConfig;
  static supportedBrowsers: SupportedBrowser[];

  static initialise(configData: ClientConfig) {
    if (AppSettings.isInitialised) {
      return;
    }

    AppSettings.SWANbuild = configData.SWANbuild;
    AppSettings.SWANversion = configData.SWANversion;
    AppSettings.app = configData.app;
    AppSettings.appInsights = configData.appInsights;
    AppSettings.auth0 = configData.auth0;
    AppSettings.i18nexus = configData.i18nexus;
    AppSettings.supportedBrowsers = configData.supportedBrowsers;

    // Set inferred 'envCode' from 'clientUrl' value.
    AppSettings.app.envCode = AppUtils.getEnvironmentCode(AppSettings.app.clientUrl);

    AppSettings.isInitialised = true;
  }
}
