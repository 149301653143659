export enum AssetClassNameEnum {
  Allocation = 'Allocation',
  BudgetNutrientProfile = 'BudgetNutrientProfile',
  DepthMeter = 'Depth Meter',
  Fertiliser = 'Fertiliser',
  IrrigationController = 'Irrigation Controller',
  IrrigationPlan = 'Irrigation Plan',
  NutrientProfile = 'NutrientProfile',
  SiteCMU = 'Site Group (CMU)',
  SiteHealthArea = 'Site Health Area',
  SiteHealthPoint = 'Site Health Point',
  SiteIMU = 'Site (IMU)',
  SiteWaterArea = 'Site Water Area',
  SoilMoisture = 'Soil Moisture',
  WaterFlowMeter = 'Water Flow Meter',
  WaterPipe = 'Water Pipe',
  WaterSamplePoint = 'Water Sample Point',
  WaterStore = 'Water Store',
  WaterValve = 'Water Valve',
  WeatherStation = 'Weather Station',
}
