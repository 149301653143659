import { EnvCodes } from '@indicina/swan-shared/constants/EnvCodes';

export class AppUtils {
  private static _selectedAccountName = '';

  static get selectedAccountName(): string {
    return AppUtils._selectedAccountName;
  }

  static updateAppTitle(envCode: string | null): void {
    if (!envCode || envCode === EnvCodes.PRD) {
      return;
    }

    // For non-prod environments app title is update to show the enviroment (e.g. SWAN (DEV), etc.).
    document.title = `SWAN (${envCode})`;
  }

  static getEnvironmentCode(url: string): string {
    // Regular expressions to match http:// or https:// followed by any characters up to the first dot.
    const regexForLocal = /^(?:http:\/\/)([^.]+)\./;
    const regexForRemote = /^(?:https:\/\/)([^.]+)\./;

    // Execute the regex on the input URL.
    let match = url.match(regexForLocal);

    if (match) {
      return EnvCodes.LOCAL;
    }

    match = url.match(regexForRemote);

    // If there is a match, return the first captured group.
    if (match) {
      const prdSubDomain = 'app';
      const currentSubDomain = match[1]?.toLowerCase();

      return currentSubDomain !== prdSubDomain ? currentSubDomain.toUpperCase() : EnvCodes.PRD;
    }

    // If no match is found, return null.
    throw new Error(`Could not infer the enviroment from '${url}' resource`);
  }

  static updateSelectedAccountName(accountName: string): void {
    AppUtils._selectedAccountName = accountName;
  }
}
