export class StringUtils {
  static isJson(input: string): boolean {
    try {
      JSON.parse(input);

      return true; // String is valid JSON.
    } catch (e) {
      return false; // String is not valid JSON.
    }
  }

  static isLikeTranslationKey(input: string): boolean {
    const regex = /^[a-zA-Z0-9.]+$/;

    return regex.test(input);
  }

  static removeLeadingChars(input: string, chars: string): string {
    if (input?.startsWith(chars)) {
      return input.slice(chars.length);
    }

    return input;
  }

  static removeTrailingChars(input: string, chars: string): string {
    if (input?.endsWith(chars)) {
      return input.slice(0, -chars.length);
    }

    return input;
  }

  static truncate(input: string, maxLength: number, suffix = '...'): string {
    return input?.length > maxLength
      ? `${input.slice(0, maxLength - (suffix?.length ?? 0))}${suffix}`
      : input;
  }

  static parseToXML(xmlString: string): XMLDocument {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');

    // Check for parsing errors
    const parseError = xmlDoc.getElementsByTagName('parsererror');

    if (parseError.length) {
      throw new Error(`Error parsing XML: ${parseError[0].textContent}`);
    }

    return xmlDoc;
  }
}

export class StringComparer {
  static compareStrings(x: string, y: string): number {
    // eslint-disable-next-line eqeqeq
    if (x === y) {
      return 0;
    }

    return (x ?? '').localeCompare(y ?? '', undefined, { sensitivity: 'base' });
  }
}
