import { IsoWeekday } from '@indicina/swan-shared/utils/DateUtils';

export interface IContextData {
  accountId: number;
  assetId: number;
  budgetId: number;
  equipmentId: number;
  groupId: number;
  locale: string;
  longitude: number;
  planId: number;
  siteId: number;
  startDayNumber: number;
  startOfWeek: IsoWeekday;
  tab1: number;
  tab2: number;
  tab3: number;
}

export class LocalStorageUtils {
  private static logToConsole: ((message: string) => void) | undefined;

  static get contextData(): IContextData {
    return LocalStorageUtils.get('contextData') as IContextData;
  }

  static configureLogging(loggingFunc?: (message: string) => void): void {
    LocalStorageUtils.logToConsole = loggingFunc;
  }

  static set(path: string, value: any): void {
    let val = value;

    if (
      typeof val === 'string' ||
      typeof val === 'number' ||
      typeof val === 'boolean' ||
      val instanceof Date
    ) {
      if (val instanceof Date) {
        val = val.toJSON();
      }

      LocalStorageUtils.write(path, val.toString());
    } else {
      LocalStorageUtils.writeObject(path, val);
    }
  }

  static get<T>(key: string): T | null {
    return LocalStorageUtils.read<T>(key);
  }

  static remove(path: string): void {
    localStorage.removeItem(path);

    LocalStorageUtils.logToConsole?.(`LocalStorageService::remove(${path})`);
  }

  static clear(): void {
    localStorage.clear();

    LocalStorageUtils.logToConsole?.(
      'LocalStorageService::clear - all items removed from local storage',
    );
  }

  static setContextData(contextData: IContextData | object): void {
    LocalStorageUtils.set('contextData', contextData);
  }

  static updateContextData(updateFn: (contextData: IContextData) => void): void {
    const context = LocalStorageUtils.contextData;

    updateFn(context);

    LocalStorageUtils.setContextData(context);
  }

  private static read<T>(path: string): T | null {
    const getValue = (path: string): string | null => {
      const value = localStorage.getItem(path);

      LocalStorageUtils.logToConsole?.(
        `LocalStorageService::read(' + path + ')${!value ? ' - path not found' : ''}`,
      );

      return value;
    };

    const isISO8601 = (dateString: string): boolean => {
      const iso8601Regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)Z$/;

      return iso8601Regex.test(dateString);
    };

    const value = getValue(path);

    if (!value) {
      return null;
    }

    try {
      const result = JSON.parse(value) as T;

      return result;
    } catch (error) {
      if (isISO8601(value)) {
        return new Date(value) as T;
      }

      return value as T;
    }
  }

  private static write(path: string, value: string): void {
    localStorage.setItem(path, value);

    LocalStorageUtils.logToConsole?.(`LocalStorageService::write(${path})`);
  }

  private static writeObject(path: string, data: object): void {
    const json = JSON.stringify(data);

    LocalStorageUtils.write(path, json);
  }
}
