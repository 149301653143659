/// <reference types="@types/google.maps" />
import { GeometryTypeEnum } from '@indicina/swan-shared/enums/GeometryTypeEnum';
import { GeoUtils } from '@indicina/swan-shared/utils/GeoUtils';
import { centroid } from '@turf/turf';
import {
  Feature,
  FeatureCollection,
  GeoJSON,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from 'geojson';

interface GoogleMapPolygonUtils {
  getCentre: (
    polygon: google.maps.Polygon | google.maps.Data.Polygon,
  ) => google.maps.LatLngLiteral | null;
  polygonToGeoJson: (polygon: google.maps.Polygon) => Feature;
  toLatLngArrays: (
    geoJSON: Feature<Polygon> | FeatureCollection<Polygon>,
  ) => google.maps.LatLngLiteral[][][];
}

interface GoogleMapsUiUtils {
  configureMapSearchInput: (map: google.maps.Map, searchInputId: string) => void;
}

export class GoogleMapUtils {
  static getCentre(geoJSON: GeoJSON): google.maps.LatLngLiteral | null {
    const coord = centroid(geoJSON)?.geometry.coordinates;

    if (!coord) {
      return null;
    }

    return { lat: coord[1], lng: coord[0] };
  }

  static isSelfIntersected(points: google.maps.LatLng[]): boolean {
    if (points.length < 4) {
      return false;
    }

    for (let i = 0; i <= points.length - 3; i++) {
      const a = points[i].lng();
      const b = points[i].lat();
      const c = points[i + 1].lng();
      const d = points[i + 1].lat();

      for (let j = i + 2; j <= points.length - 1; j++) {
        const p = points[j].lng();
        const q = points[j].lat();
        const r = points[j + 1 === points.length ? 0 : j + 1].lng();
        const s = points[j + 1 === points.length ? 0 : j + 1].lat();
        const det = (c - a) * (s - q) - (r - p) * (d - b);

        if (det) {
          const lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
          const gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
          const isIntersected = 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;

          if (isIntersected) {
            return true;
          }
        }
      }
    }

    return false;
  }

  /** Converts a google.maps.Data.Geometry to GeoJSON */
  static toGeoJson(
    geometry: google.maps.Data.Geometry | google.maps.LatLng | google.maps.LatLngLiteral,
  ): GeoJSON | undefined {
    if (!geometry) {
      return undefined;
    }

    const isGeometry = (): boolean => {
      return typeof (geometry as google.maps.Data.Geometry).getType === 'function';
    };

    if (isGeometry()) {
      const geometryType = (geometry as google.maps.Data.Geometry).getType();

      switch (geometryType) {
        case GeometryTypeEnum.Point: {
          const point = (geometry as google.maps.Data.Point).get();

          return <Point>{
            type: GeometryTypeEnum.Point,
            coordinates: [point.lng(), point.lat()],
          };
        }

        case GeometryTypeEnum.MultiPoint: {
          const points = (geometry as google.maps.Data.MultiPoint).getArray();

          return <MultiPoint>{
            type: GeometryTypeEnum.MultiPoint,
            coordinates: points.map((p) => [p.lng(), p.lat()]),
          };
        }

        case GeometryTypeEnum.LineString: {
          return <LineString>{
            type: GeometryTypeEnum.LineString,
            coordinates: GoogleMapUtils.toGeoJsonLineStringCoords(
              geometry as google.maps.Data.LineString,
            ),
          };
        }

        case GeometryTypeEnum.MultiLineString: {
          const lines = (geometry as google.maps.Data.MultiLineString).getArray();

          return <MultiLineString>{
            type: GeometryTypeEnum.MultiLineString,
            coordinates: lines.map((p) => GoogleMapUtils.toGeoJsonLineStringCoords(p)),
          };
        }

        case GeometryTypeEnum.Polygon: {
          return <Polygon>{
            type: GeometryTypeEnum.Polygon,
            coordinates: GoogleMapUtils.toGeoJsonPolygonCoords(
              geometry as google.maps.Data.Polygon,
            ),
          };
        }

        case GeometryTypeEnum.MultiPolygon: {
          const polygons = (geometry as google.maps.Data.MultiPolygon).getArray();

          return <MultiPolygon>{
            type: GeometryTypeEnum.MultiPolygon,
            coordinates: polygons.map((p) => GoogleMapUtils.toGeoJsonPolygonCoords(p)),
          };
        }
      }

      throw new Error(
        `google.maps.Data.Geometry of type '${geometryType}' cannot be converted GeoJSON`,
      );
    }

    const isLatLngLiteral = (): boolean => {
      return (
        typeof (geometry as google.maps.LatLngLiteral)?.lat === 'number' &&
        typeof (geometry as google.maps.LatLngLiteral)?.lng === 'number'
      );
    };

    if (isLatLngLiteral()) {
      return <Point>{
        type: GeometryTypeEnum.Point,
        coordinates: [
          (geometry as google.maps.LatLngLiteral).lng,
          (geometry as google.maps.LatLngLiteral).lat,
        ],
      };
    }

    return <Point>{
      type: GeometryTypeEnum.Point,
      coordinates: [(geometry as google.maps.LatLng).lng(), (geometry as google.maps.LatLng).lat()],
    };
  }

  static Polygons: GoogleMapPolygonUtils = {
    getCentre: (polygon: google.maps.Polygon | google.maps.Data.Polygon) =>
      this.getCenterPolygon(polygon),
    polygonToGeoJson: (polygon: google.maps.Polygon) => GoogleMapUtils.polygonToGeoJson(polygon),
    toLatLngArrays: (geoJSON: Feature<Polygon> | FeatureCollection<Polygon>) =>
      GoogleMapUtils.toLatLngArraysPolygon(geoJSON),
  };

  static UI: GoogleMapsUiUtils = {
    configureMapSearchInput: (map: google.maps.Map, searchInputId: string) =>
      this.configureMapSearchInput(map, searchInputId),
  };

  // Core
  private static isSameFirstAndLastCoordinates(coords: google.maps.LatLngLiteral[]): boolean {
    if (coords.length <= 1) {
      return false;
    }

    const first = coords[0];
    const last = coords[coords.length - 1];

    return first.lat === last.lat && first.lng === last.lng;
  }

  // LineStrings
  private static toGeoJsonLineStringCoords(lineString: google.maps.Data.LineString): number[][] {
    return lineString.getArray().map((ll) => [ll.lng(), ll.lat()]);
  }

  // Polygons
  private static getCenterPolygon(
    polygon: google.maps.Polygon | google.maps.Data.Polygon,
  ): google.maps.LatLngLiteral | null {
    let coord: Position = [];

    if (polygon instanceof google.maps.Polygon) {
      coord = centroid(GoogleMapUtils.polygonToGeoJson(polygon))?.geometry.coordinates;
    } else if (polygon instanceof google.maps.Data.Polygon) {
      const geoJSON = GoogleMapUtils.toGeoJson(polygon);

      if (!geoJSON) {
        return null;
      }

      coord = centroid(geoJSON)?.geometry.coordinates;
    }

    if (!coord) {
      return null;
    }

    return { lat: coord[1], lng: coord[0] };
  }

  private static polygonToGeoJson(polygon: google.maps.Polygon): Feature {
    const paths = polygon.getPath().getArray();
    const coordinates = paths.map((latlng) => [latlng.lng(), latlng.lat()]);

    // Close the polygon loop by repeating the first coordinate
    coordinates.push(coordinates[0]);

    return {
      type: GeometryTypeEnum.Feature,
      geometry: {
        type: GeometryTypeEnum.Polygon,
        coordinates: [coordinates],
      },
      properties: null,
    };
  }

  /** Converts GeoJSON to google.maps.LatLngLiteral arrays **/
  private static toLatLngArraysPolygon(
    geoJSON: Feature<Polygon> | FeatureCollection<Polygon>,
  ): google.maps.LatLngLiteral[][][] {
    if (!GeoUtils.isValidGeoJSON(geoJSON)) {
      return [];
    }

    const geometry =
      (geoJSON as FeatureCollection)?.features?.[0].geometry ?? (geoJSON as Feature)?.geometry;

    return geometry.type === GeometryTypeEnum.MultiPolygon
      ? geometry.coordinates.map((polygon) => GoogleMapUtils.toLatLngArrayPolygon(polygon))
      : [GoogleMapUtils.toLatLngArrayPolygon((geometry as Polygon).coordinates)];
  }

  private static toLatLngArrayPolygon(polygon: Position[][]): google.maps.LatLngLiteral[][] {
    const coords = polygon.map((ring) => ring.map((point) => ({ lat: point[1], lng: point[0] })));

    // For each array of coords, if the first and last lat/lng are identical then drop one as
    // google maps does not need it to draw the closed polygon.
    coords.forEach((coord) => {
      if (GoogleMapUtils.isSameFirstAndLastCoordinates(coord)) {
        coord.pop();
      }
    });

    return coords;
  }

  private static toGeoJsonPolygonCoords(polygon: google.maps.Data.Polygon): number[][][] {
    return polygon.getArray().map((ring) => {
      const coords = ring.getArray().map((coord) => [coord.lng(), coord.lat()]);

      const first = coords[0];
      const last = coords[coords.length - 1];

      // If first/last don't match, we need to add it to complete the loop for legacy layers.
      if (first[0] !== last[0] || first[1] !== last[1]) {
        coords.push(coords[0]);
      }

      return coords;
    });
  }

  // UI
  private static configureMapSearchInput(map: google.maps.Map, searchInputId: string): void {
    const input = document.getElementById(searchInputId) as HTMLInputElement;

    const handleChangeInput = (): void => {
      const value = input.value.trim();

      if (GeoUtils.isCoordinate(value)) {
        // Extract and parse latitude and longitude
        const [lat, lng] = value.split(',').map(Number);

        if (GeoUtils.isValidLatLng(lat, lng)) {
          handleSuccessResult(new google.maps.LatLng(lat, lng));
        } else {
          alert('Invalid latitude or longitude values.');
        }
      }
    };

    const handleSuccessResult = (latLng: google.maps.LatLng): void => {
      // If the place has a location, center the map on that location.
      map.setCenter(latLng);
      map.setZoom(15);

      // Place a marker at the selected place.
      marker.setPosition(latLng);
      marker.setVisible(true);
    };

    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['geocode'],
    });

    autocomplete.bindTo('bounds', map); // Bias results to map's current bounds.

    const marker = new google.maps.Marker({
      map: map,
    });

    // Event listener for when a place is selected from autocomplete suggestions.
    autocomplete.addListener('place_changed', () => {
      marker.setVisible(false);

      const place = autocomplete.getPlace();

      if (GeoUtils.isCoordinate(place.name)) {
        handleChangeInput();

        return;
      }

      // Check if the place has a geometry (location).
      if (!place.geometry?.location) {
        window.alert(`No location available for input: ${place.name}`);

        return;
      }

      handleSuccessResult(place.geometry.location);
    });

    input.addEventListener('change', handleChangeInput);
  }
}
