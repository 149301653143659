import { ILanguageService } from '@indicina/swan-shared/interfaces/ILanguageService';
import { getService } from 'react-in-angularjs';
import { injectable } from 'inversify';

@injectable()
export class AngularLanguageService implements ILanguageService {
  instant: (key: string) => string = (key) => {
    const languageService = getService('LanguageService');

    return languageService.instant(key);
  };
}
