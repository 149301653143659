import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { DateTime, DateTimeFormatOptions, Info, Settings, StringUnitLength } from 'luxon';

export enum IsoWeekday {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export class DateUtils {
  static Locale = {
    AngularJS: {
      DatePicker: {
        dayAndMonthShort: () => ({
          formatDate: (input: Date) => this.Locale.asDateDayAndMonth(input),
        }),
        monthAndYearShort: () => ({
          formatDate: (input: Date) => this.Locale.asDateMonthAndYearShort(input),
        }),
      },
    },
    StartOfWeek: IsoWeekday.Monday,
    Weekdays: {
      Long: {
        Monday: '',
        Tuesday: '',
        Wednesday: '',
        Thursday: '',
        Friday: '',
        Saturday: '',
        Sunday: '',
      },
      Short: {
        Monday: '',
        Tuesday: '',
        Wednesday: '',
        Thursday: '',
        Friday: '',
        Saturday: '',
        Sunday: '',
      },
    },
    asDateDayAndMonth: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        day: 'numeric',
        month: 'short',
      }),
    asDateDayAndMonthWithWeekday: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
      }),
    asDateDayWithWeekday: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        weekday: 'short',
        day: 'numeric',
      }),
    asDateDefault: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, DateTime.DATE_MED_WITH_WEEKDAY),
    asDateMedium: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, DateTime.DATE_MED),
    asDateMonthNameLong: (monthNumber: number) =>
      DateTime.fromObject({ month: monthNumber }).monthLong,
    asDateMonthAndYearLong: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        month: 'long',
        year: 'numeric',
      }),
    asDateMonthAndYearShort: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        month: 'short',
        year: 'numeric',
      }),
    asDateMonthShort: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        month: 'short',
      }),
    asDateShort: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, DateTime.DATE_SHORT),
    asDateWeekdayLong: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        weekday: 'long',
      }),
    asDateWeekdayShort: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        weekday: 'short',
      }),
    asDateTimeDayAndMonthAndTime: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: '2-digit',
      }),
    asDateTimeMedium: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, DateTime.DATETIME_MED),
    asDateTimeMediumWithWeekday: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, DateTime.DATETIME_MED_WITH_WEEKDAY),
    asTimeSimple: (input: number | string | Date) =>
      DateUtils.getLocaleString(input, DateTime.TIME_SIMPLE),
    getAvailableLocales: (languageCode?: string) => DateUtils.getAvailableLocales(languageCode),
    getDays: () => Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0')),
    getHours24Format: () => Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0')),
    getMonthNameShort: (monthNumber: number) =>
      DateTime.fromObject({ month: monthNumber }).monthShort,
    getMonthNamesLong: (locale?: string) =>
      Info.months('long', { locale: locale ?? Settings.defaultLocale }),
    getMonthNamesShort: (locale?: string) =>
      Info.months('short', { locale: locale ?? Settings.defaultLocale }),
    getStartOfWeek: () => DateUtils.getStartOfWeek(),
    getWeekdayNamesLong: (customStartOfWeek?: IsoWeekday, locale?: string) =>
      DateUtils.getWeekdayNamesLong(customStartOfWeek, locale),
    getWeekdayNamesShort: (customStartOfWeek?: IsoWeekday, locale?: string) =>
      DateUtils.getWeekdayNamesShort(customStartOfWeek, locale),
    setLocale: (locale: string) => DateUtils.setLocale(locale),
    setStartOfWeek: (startOfWeek: IsoWeekday) => {
      DateUtils.Locale.StartOfWeek = startOfWeek;
    },
  };

  static getBrowserTimezoneLabel(): string {
    const browserTimezoneLabel = new Date()
      .toLocaleString(undefined, { timeZoneName: 'long' })
      .split(' ')
      .slice(3)
      .join(' ');

    return browserTimezoneLabel;
  }

  // Common
  private static getLuxonDate(input: number | string | Date): DateTime | null {
    const ld = DateTime.fromJSDate(new Date(input));

    return ld.isValid ? ld : null;
  }

  private static setLocale(locale: string): void {
    Settings.defaultLocale = locale;

    // Reset the relevant constants after the locale is set, so they contain the values of the new locale.
    const longDayNames = DateUtils.getWeekdayNamesLong(IsoWeekday.Monday);

    DateUtils.Locale.Weekdays.Long.Monday = longDayNames[0];
    DateUtils.Locale.Weekdays.Long.Tuesday = longDayNames[1];
    DateUtils.Locale.Weekdays.Long.Wednesday = longDayNames[2];
    DateUtils.Locale.Weekdays.Long.Thursday = longDayNames[3];
    DateUtils.Locale.Weekdays.Long.Friday = longDayNames[4];
    DateUtils.Locale.Weekdays.Long.Saturday = longDayNames[5];
    DateUtils.Locale.Weekdays.Long.Sunday = longDayNames[6];

    const shortDayNames = DateUtils.getWeekdayNamesShort(IsoWeekday.Monday);

    DateUtils.Locale.Weekdays.Short.Monday = shortDayNames[0];
    DateUtils.Locale.Weekdays.Short.Tuesday = shortDayNames[1];
    DateUtils.Locale.Weekdays.Short.Wednesday = shortDayNames[2];
    DateUtils.Locale.Weekdays.Short.Thursday = shortDayNames[3];
    DateUtils.Locale.Weekdays.Short.Friday = shortDayNames[4];
    DateUtils.Locale.Weekdays.Short.Saturday = shortDayNames[5];
    DateUtils.Locale.Weekdays.Short.Sunday = shortDayNames[6];
  }

  private static getLocaleString(
    input: number | string | Date,
    format: DateTimeFormatOptions,
  ): string {
    const ld = DateUtils.getLuxonDate(input);
    const result = ld?.toLocaleString(format) ?? '';

    return result;
  }

  private static getWeekdayNamesForStartOfWeek(
    length: StringUnitLength,
    customStartOfWeek?: IsoWeekday,
    locale = Settings.defaultLocale,
  ): string[] {
    const startOfWeek = customStartOfWeek ?? DateUtils.getStartOfWeek();
    const weekdays = Info.weekdays(length, { locale: locale });

    // The 'startOfWeek' is 1-based (1 = Monday, 7 = Sunday)
    // NOTE: Result array must have 'startOfweek' day at index 0.
    return weekdays.slice(startOfWeek - 1).concat(weekdays.slice(0, startOfWeek - 1));
  }

  private static getStartOfWeek(): IsoWeekday {
    return DateUtils.Locale.StartOfWeek;
  }

  private static getWeekdayNamesLong(customStartOfWeek?: IsoWeekday, locale?: string): string[] {
    return DateUtils.getWeekdayNamesForStartOfWeek('long', customStartOfWeek, locale);
  }

  private static getWeekdayNamesShort(customStartOfWeek?: IsoWeekday, locale?: string): string[] {
    return DateUtils.getWeekdayNamesForStartOfWeek('short', customStartOfWeek, locale);
  }

  private static getAvailableLocales(languageCode?: string): string[] {
    const languages = AppSettings.i18nexus.supportedLanguages.map((x) => x.code); // Supported i18nexus languages (ISO 639-1 language codes).
    const regions = AppSettings.app.languageRegions; // Language regions for potential combinations.

    // Generate locale combinations.
    const testLocales = [];

    for (const lang of languages) {
      testLocales.push(lang); // Add standalone language.

      for (const region of regions) {
        testLocales.push(`${lang}-${region}`); // Add language-region combos.
      }
    }

    // Filter valid locales using the 'Intl.DateTimeFormat' constructor.
    return testLocales.filter((locale) => {
      try {
        new Intl.DateTimeFormat(locale);

        return !languageCode || locale.startsWith(languageCode);
      } catch {
        return false;
      }
    });
  }
}
