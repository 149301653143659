import { StringUtils } from '@indicina/swan-shared/utils/StringUtils';

export class HttpUtils {
  static removeLeadingSlash(input: string): string {
    return StringUtils.removeLeadingChars(input, '/');
  }

  static removeTrailingSlash(input: string): string {
    return StringUtils.removeTrailingChars(input, '/');
  }

  static appendQueryString(url: string, queryString: string): string {
    // Check if the URL already contains a query string.
    const hasQueryString = url.includes('?');

    // Determine the separator to use based on whether the URL already has a query string.
    const separator = hasQueryString ? '&' : '?';

    // Append the query string to the URL.
    const updatedUrl = url + separator + queryString;

    return updatedUrl;
  }
}
