import { GeometryTypeEnum } from '@indicina/swan-shared/enums/GeometryTypeEnum';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { StringUtils } from '@indicina/swan-shared/utils/StringUtils';
import { area } from '@turf/turf';
import { Feature, FeatureCollection, GeoJSON } from 'geojson';

export class GeoUtils {
  static isValidGeoJSON(geoJSON: GeoJSON): boolean {
    return !!(
      (geoJSON as FeatureCollection)?.features?.[0].geometry ?? (geoJSON as Feature)?.geometry
    )?.type;
  }

  // Function to validate latitude and longitude ranges.
  static isValidLatLng(lat: number, lng: number): boolean {
    return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  }

  static isCoordinate(value?: string): boolean {
    if (!value) {
      return false;
    }

    // Check if input looks like coordinates (latitude, longitude).
    const coordPattern = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;

    return coordPattern.test(value);
  }

  public static uploadKmlOrGeoJSON(notifyingService: {
    notify: (eventName: string, data?: any) => void;
  }) {
    const fileInput: any = document.getElementById('file');
    const file = fileInput.files[0];
    const fileReader = new FileReader();

    fileReader.readAsText(file);

    fileReader.onloadend = (e) => {
      const content = e.target?.result as string;

      if (!content) {
        return;
      }

      let featureCollection: FeatureCollection;

      if (file.name.endsWith('json')) {
        featureCollection = JSON.parse(content) as FeatureCollection;
      } else {
        featureCollection = GeoUtils.Helpers.geoJSON.kml(content);
      }

      const feature = featureCollection.features[0] as Feature; // NOTE: Only taking the first feature.
      let paths: { lat: number; lng: number }[] = [];

      switch (feature.geometry.type) {
        case GeometryTypeEnum.LineString:
          paths = feature.geometry.coordinates.map((position) => ({
            lat: position[1],
            lng: position[0],
          }));

          break;
        case GeometryTypeEnum.Polygon:
          paths = ArrayUtils.flatMap(
            feature.geometry.coordinates.map((positions) =>
              positions.map((position) => ({ lat: position[1], lng: position[0] })),
            ),
            (x) => x,
          );

          break;
        case GeometryTypeEnum.MultiPolygon:
          // TODO: Handle multi polygons.

          break;
        default:
          break;
      }

      notifyingService.notify('Map.MapDataUploaded', { featureCollection, paths });
    };
  }

  static GeoJSON = {
    getAreaInHectares: (geoJSON: GeoJSON) => area(geoJSON) / 10000,
  };

  static Helpers = {
    geoJSON: this.getGeoJsonHelper(),
  };

  private static getGeoJsonHelper() {
    const removeSpace = /\s*/g;
    const trimSpace = /^\s*|\s*$/g;
    const splitSpace = /\s+/;

    let serializer: XMLSerializer;

    // generate a short, numeric hash of a string
    const getOkHash = (x: string) => {
      let h = 0;

      if (!x?.length) {
        return h;
      }

      for (let i = 0; i < x.length; i++) {
        h = ((h << 5) - h + x.charCodeAt(i)) | 0;
      }

      return h;
    };

    // all Y children of X
    const getElements = (x: Element, y: string): Element[] => {
      return Array.from(x.getElementsByTagName(y));
    };

    // First Y child of X, if any, otherwise null
    const getFirstChild = (x: Element, y: string): Element => {
      const n = getElements(x, y);

      return n?.[0];
    };

    const getAttr = (x: Element, y: string): string => {
      return x.getAttribute(y) ?? '';
    };

    const getAttrAsNumber = (x: Element, y: string): number => {
      return Number.parseFloat(getAttr(x, y) ?? '');
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/Node.normalize
    const normalise = (el: Element) => {
      if (el.normalize) {
        el.normalize();
      }
      return el;
    };

    // cast array x into numbers
    const getNumArray = (x: string[]) => {
      const o = [];

      for (let j = 0; j < x.length; j++) {
        o[j] = Number.parseFloat(x[j]);
      }

      return o;
    };

    const clean = (x: any) => {
      const o: any = {};

      for (const i in x) {
        if (x[i]) {
          o[i] = x[i];
        }
      }

      return o;
    };

    // Get the content of a text node, if any
    const getNodeContent = (x: Element) => {
      if (x) {
        normalise(x);
      }

      return x?.textContent || '';
    };

    // Get one coordinate from a coordinate array, if any
    const coord1 = (v: string) => {
      return getNumArray(v.replace(removeSpace, '').split(','));
    };

    // Get all coordinates from a coordinate array as [[],[]]
    const coord = (v: string) => {
      const coords = v.replace(trimSpace, '').split(splitSpace);

      return coords.map((coord) => coord1(coord));
    };

    const coordPair = (x: Element) => {
      const ll = [getAttrAsNumber(x, 'lon'), getAttrAsNumber(x, 'lat')];
      const ele = getFirstChild(x, 'ele');
      const heartRate = getFirstChild(x, 'gpxtpx:hr') || getFirstChild(x, 'hr'); // Handle namespaced attribute in browser
      const time = getFirstChild(x, 'time');

      if (ele) {
        const e = Number.parseFloat(getNodeContent(ele));

        if (!Number.isNaN(e)) {
          ll.push(e);
        }
      }

      return {
        coordinates: ll,
        heartRate: heartRate ? Number.parseFloat(getNodeContent(heartRate)) : null,
        time: time ? getNodeContent(time) : null,
      };
    };

    // Create a new feature collection parent object
    const fc = (): FeatureCollection => {
      return {
        type: GeometryTypeEnum.FeatureCollection,
        features: [],
      };
    };

    const xml2str = (str: any) => {
      if (str.xml !== undefined) {
        return str.xml;
      }

      return serializer.serializeToString(str);
    };

    const kml = (fileContent: string): FeatureCollection => {
      const doc = StringUtils.parseToXML(fileContent) as any;

      const gj = fc();
      // styleIndex keeps track of hashed styles in order to match features
      const styleIndex: any = {};
      const styleByHash: any = {};
      // styleMapIndex keeps track of style maps to expose in properties
      const styleMapIndex: any = {};
      // atomic geospatial types supported by KML - MultiGeometry is handled separately
      const geotypes = ['Polygon', 'LineString', 'Point', 'Track', 'gx:Track'];
      // all root placemarks in the file
      const placemarks = getElements(doc, 'Placemark');
      const styles = getElements(doc, 'Style');
      const styleMaps = getElements(doc, 'StyleMap');

      const kmlColor = (v = '') => {
        let color = '';
        let opacity = 0;

        const hexValue = v.replace('#', '');

        if (hexValue.length === 6 || hexValue.length === 3) {
          color = hexValue;
        }

        if (hexValue.length === 8) {
          color = `#${hexValue.substring(6, 2)}${hexValue.substring(4, 2)}${hexValue.substring(2, 2)}`;
          opacity = Number.parseInt(hexValue.substring(0, 2), 16) / 255;
        }

        return [color, Number.isNaN(opacity) ? undefined : opacity];
      };

      const gxCoord = (v: string) => {
        return getNumArray(v.split(' '));
      };

      const gxCoords = (root: Element) => {
        // const elems = get(root, 'coord', 'gx'), coords = [], times = [];
        let elems = getElements(root, 'coord');

        if (!elems.length) {
          elems = getElements(root, 'gx:coord');
        }

        const coords = elems.map((el) => gxCoord(getNodeContent(el)));
        const times = getElements(root, 'when').map((el) => getNodeContent(el));

        return {
          coords: coords,
          times: times,
        };
      };

      const getGeometry = (root: Element) => {
        if (getFirstChild(root, 'MultiGeometry')) {
          return getGeometry(getFirstChild(root, 'MultiGeometry'));
        }

        if (getFirstChild(root, 'MultiTrack')) {
          return getGeometry(getFirstChild(root, 'MultiTrack'));
        }

        if (getFirstChild(root, 'gx:MultiTrack')) {
          return getGeometry(getFirstChild(root, 'gx:MultiTrack'));
        }

        let geomNodes: Element[];
        let geomNode: Element;
        const geoms = [];
        const coordTimes = [];

        // biome-ignore lint/style/useForOf: <explanation>
        for (let i = 0; i < geotypes.length; i++) {
          geomNodes = getElements(root, geotypes[i]);

          if (geomNodes) {
            // biome-ignore lint/style/useForOf: <explanation>
            for (let j = 0; j < geomNodes.length; j++) {
              geomNode = geomNodes[j];

              if (geotypes[i] === 'Point') {
                geoms.push({
                  type: 'Point',
                  coordinates: coord1(getNodeContent(getFirstChild(geomNode, 'coordinates'))),
                });
              } else if (geotypes[i] === 'LineString') {
                geoms.push({
                  type: 'LineString',
                  coordinates: coord(getNodeContent(getFirstChild(geomNode, 'coordinates'))),
                });
              } else if (geotypes[i] === 'Polygon') {
                const rings = getElements(geomNode, 'LinearRing');
                const coords = [];

                // biome-ignore lint/style/useForOf: <explanation>
                for (let k = 0; k < rings.length; k++) {
                  coords.push(coord(getNodeContent(getFirstChild(rings[k], 'coordinates'))));
                }

                geoms.push({
                  type: 'Polygon',
                  coordinates: coords,
                });
              } else if (geotypes[i] === 'Track' || geotypes[i] === 'gx:Track') {
                const track = gxCoords(geomNode);

                geoms.push({
                  type: 'LineString',
                  coordinates: track.coords,
                });

                if (track.times.length) {
                  coordTimes.push(track.times);
                }
              }
            }
          }
        }

        return {
          coordTimes: coordTimes,
          geoms: geoms,
        };
      };

      const getPlacemark = (root: Element) => {
        const geomsAndTimes = getGeometry(root);

        if (!geomsAndTimes.geoms.length) {
          return [];
        }

        const name = getNodeContent(getFirstChild(root, 'name'));
        const description = getNodeContent(getFirstChild(root, 'description'));
        const timeSpan = getFirstChild(root, 'TimeSpan');
        const timeStamp = getFirstChild(root, 'TimeStamp');
        const extendedData = getFirstChild(root, 'ExtendedData');
        const visibility = getFirstChild(root, 'visibility');
        const properties: any = {};

        let styleUrl = getNodeContent(getFirstChild(root, 'styleUrl'));
        let lineStyle = getFirstChild(root, 'LineStyle');
        let polyStyle = getFirstChild(root, 'PolyStyle');

        if (name) {
          properties.name = name;
        }

        if (styleUrl) {
          if (styleUrl[0] !== '#') {
            styleUrl = `#${styleUrl}`;
          }

          properties.styleUrl = styleUrl;

          if (styleIndex[styleUrl]) {
            properties.styleHash = styleIndex[styleUrl];
          }

          if (styleMapIndex[styleUrl]) {
            properties.styleMapHash = styleMapIndex[styleUrl];
            properties.styleHash = styleIndex[styleMapIndex[styleUrl].normal];
          }

          // Try to populate the lineStyle or polyStyle since we got the style hash
          const style = styleByHash[properties.styleHash];

          if (style) {
            if (!lineStyle) {
              lineStyle = getFirstChild(style, 'LineStyle');
            }

            if (!polyStyle) {
              polyStyle = getFirstChild(style, 'PolyStyle');
            }
          }
        }

        if (description) {
          properties.description = description;
        }

        if (timeSpan) {
          const begin = getNodeContent(getFirstChild(timeSpan, 'begin'));
          const end = getNodeContent(getFirstChild(timeSpan, 'end'));

          properties.timespan = { begin: begin, end: end };
        }

        if (timeStamp) {
          properties.timestamp = getNodeContent(getFirstChild(timeStamp, 'when'));
        }

        if (lineStyle) {
          const lineStyles = kmlColor(getNodeContent(getFirstChild(lineStyle, 'color')));
          const color = lineStyles[0] as string;
          const opacity = lineStyles[1] as number;
          const width = Number.parseFloat(getNodeContent(getFirstChild(lineStyle, 'width')));

          if (color) {
            properties.stroke = color;
          }

          if (!Number.isNaN(opacity)) {
            properties['stroke-opacity'] = opacity;
          }

          if (!Number.isNaN(width)) {
            properties['stroke-width'] = width;
          }
        }

        if (polyStyle) {
          const polyStyles = kmlColor(getNodeContent(getFirstChild(polyStyle, 'color')));
          const polyColor = polyStyles[0] as string;
          const polyOpacity = polyStyles[1] as number;
          const fill = getNodeContent(getFirstChild(polyStyle, 'fill'));
          const outline = getNodeContent(getFirstChild(polyStyle, 'outline'));

          if (polyColor) {
            properties.fill = polyColor;
          }

          if (!Number.isNaN(polyOpacity)) {
            properties['fill-opacity'] = polyOpacity;
          }

          if (fill) {
            properties['fill-opacity'] = fill === '1' ? properties['fill-opacity'] || 1 : 0;
          }

          if (outline) {
            properties['stroke-opacity'] = outline === '1' ? properties['stroke-opacity'] || 1 : 0;
          }
        }

        if (extendedData) {
          const datas = getElements(extendedData, 'Data');
          const simpleDatas = getElements(extendedData, 'SimpleData');

          datas.forEach((data) => {
            const propName = data.getAttribute('name');

            if (propName) {
              properties[propName] = getNodeContent(getFirstChild(data, 'value'));
            }
          });

          simpleDatas.forEach((data) => {
            const propName = data.getAttribute('name');

            if (propName) {
              properties[propName] = getNodeContent(data);
            }
          });
        }

        if (visibility) {
          properties.visibility = getNodeContent(visibility);
        }

        if (geomsAndTimes.coordTimes.length) {
          properties.coordTimes =
            geomsAndTimes.coordTimes.length === 1
              ? geomsAndTimes.coordTimes[0]
              : geomsAndTimes.coordTimes;
        }

        const feature = {
          id: '',
          type: GeometryTypeEnum.Feature,
          geometry:
            geomsAndTimes.geoms.length === 1
              ? geomsAndTimes.geoms[0]
              : {
                  type: GeometryTypeEnum.GeometryCollection,
                  geometries: geomsAndTimes.geoms,
                },
          properties: properties,
        } as Feature;

        if (getAttr(root, 'id')) {
          feature.id = getAttr(root, 'id');
        }

        return [feature];
      };

      // biome-ignore lint/style/useForOf: <explanation>
      for (let k = 0; k < styles.length; k++) {
        const hash = getOkHash(xml2str(styles[k])).toString(16);

        styleIndex[`#${getAttr(styles[k], 'id')}`] = hash;
        styleByHash[hash] = styles[k];
      }

      // biome-ignore lint/style/useForOf: <explanation>
      for (let l = 0; l < styleMaps.length; l++) {
        styleIndex[`#${getAttr(styleMaps[l], 'id')}`] = getOkHash(xml2str(styleMaps[l])).toString(
          16,
        );

        const pairs = getElements(styleMaps[l], 'Pair');
        const pairsMap: any = {};

        // biome-ignore lint/style/useForOf: <explanation>
        for (let m = 0; m < pairs.length; m++) {
          pairsMap[getNodeContent(getFirstChild(pairs[m], 'key'))] = getNodeContent(
            getFirstChild(pairs[m], 'styleUrl'),
          );
        }

        styleMapIndex[`#${getAttr(styleMaps[l], 'id')}`] = pairsMap;
      }

      // biome-ignore lint/style/useForOf: <explanation>
      for (let j = 0; j < placemarks.length; j++) {
        gj.features = gj.features.concat(getPlacemark(placemarks[j]));
      }

      return gj;
    };

    const gpx = (doc: any) => {
      const getProperties = (node: Element) => {
        const meta = ['name', 'desc', 'author', 'copyright', 'link', 'time', 'keywords'];
        const prop: any = {};

        // biome-ignore lint/style/useForOf: <explanation>
        for (let k = 0; k < meta.length; k++) {
          prop[meta[k]] = getNodeContent(getFirstChild(node, meta[k]));
        }

        return clean(prop);
      };

      const getTrack = (node: Element) => {
        const segments = getElements(node, 'trkseg');
        const track = [];
        const times = [];
        const heartRates = [];

        let line: any;

        // biome-ignore lint/style/useForOf: <explanation>
        for (let i = 0; i < segments.length; i++) {
          line = getPoints(segments[i], 'trkpt');

          if (line) {
            if (line.line) {
              track.push(line.line);
            }

            if (line.times?.length) {
              times.push(line.times);
            }

            if (line.heartRates?.length) {
              heartRates.push(line.heartRates);
            }
          }
        }

        if (!track.length) {
          return;
        }

        const properties: any = getProperties(node);

        if (times.length) {
          properties.coordTimes = track.length === 1 ? times[0] : times;
        }

        if (heartRates.length) {
          properties.heartRates = track.length === 1 ? heartRates[0] : heartRates;
        }

        return {
          type: GeometryTypeEnum.Feature,
          geometry: {
            type:
              track.length === 1 ? GeometryTypeEnum.LineString : GeometryTypeEnum.MultiLineString,
            coordinates: track.length === 1 ? track[0] : track,
          },
          properties: properties,
        } as Feature;
      };

      const getRoute = (node: Element) => {
        const line = getPoints(node, 'rtept');

        if (!line.line) {
          return;
        }

        return {
          type: GeometryTypeEnum.Feature,
          geometry: {
            type: GeometryTypeEnum.LineString,
            coordinates: line.line,
          },
          properties: getProperties(node),
        } as Feature;
      };

      const getPoint = (node: Element) => {
        const prop: any = getProperties(node);

        prop.sym = getNodeContent(getFirstChild(node, 'sym'));

        return {
          type: GeometryTypeEnum.Feature,
          geometry: {
            type: GeometryTypeEnum.Point,
            coordinates: coordPair(node).coordinates,
          },
          properties: prop,
        } as Feature;
      };

      // a feature collection
      const gj = fc();
      let feature: Feature | undefined;

      const tracks = getElements(doc, 'trk');

      // biome-ignore lint/style/useForOf: <explanation>
      for (let i = 0; i < tracks.length; i++) {
        feature = getTrack(tracks[i]);

        if (feature) {
          gj.features.push(feature);
        }
      }

      const routes = getElements(doc, 'rte');

      // biome-ignore lint/style/useForOf: <explanation>
      for (let i = 0; i < routes.length; i++) {
        feature = getRoute(routes[i]);

        if (feature) {
          gj.features.push(feature);
        }
      }

      const waypoints = getElements(doc, 'wpt');

      // biome-ignore lint/style/useForOf: <explanation>
      for (let i = 0; i < waypoints.length; i++) {
        gj.features.push(getPoint(waypoints[i]));
      }

      const getPoints = (node: Element, pointName: string): any => {
        const pts = getElements(node, pointName);
        const l = pts.length;

        if (l < 2) {
          return {}; // Invalid line in GeoJSON
        }

        const line = [];
        const times = [];
        const heartRates = [];

        for (let i = 0; i < l; i++) {
          const c = coordPair(pts[i]);

          line.push(c.coordinates);

          if (c.time) {
            times.push(c.time);
          }

          if (c.heartRate) {
            heartRates.push(c.heartRate);
          }
        }

        return {
          heartRates: heartRates,
          line: line,
          times: times,
        };
      };

      return gj;
    };

    if (typeof XMLSerializer !== 'undefined') {
      serializer = new XMLSerializer();
    }

    // Only require xmldom in a node environment
    /* else if (typeof exports === 'object' && typeof process === 'object' && !process.browser) {
      serializer = new (require('xmldom').XMLSerializer)();
    }*/

    return { kml: kml, gpx: gpx };
  }
}
