export class ConversionUtils {
  static convertToHHMM(decHours: number): string {
    if (!decHours) {
      return '00:00';
    }

    if (decHours < 0) {
      return '-- : --';
    }

    const decMinutes = Math.round(decHours * 60);
    const hrs = Math.floor(decMinutes / 60)
      .toString()
      .padStart(2, '0');
    const mins = (decMinutes % 60).toString().padStart(2, '0');

    return `${hrs}:${mins}`;
  }
}
