export enum AssetClassEnum {
  Site = 1,
  SiteWaterArea = 2,
  SiteHealthArea = 3,
  SiteGroup = 4,
  WorkAgent = 5,
  WaterStore = 6,
  WaterPipe = 7,
  WaterValve = 8,
  WaterFlowMeter = 9,
  WeatherStation = 10,
  SoilMoisture = 11,
  SiteHealthPoint = 12,
  WaterSamplePoint = 13,
  Fertiliser = 14,
  NutrientProfile = 15,
  BudgetNutrientProfile = 16,
}
