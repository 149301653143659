export class NumberUtils {
  static round(value: number, decimals = 0): number | null {
    if (value == null) {
      return null;
    }

    let dec = decimals;

    if (decimals < 0) {
      dec = 0;
    }

    if (dec === 0) {
      return Math.round(value);
    }

    const factor = 10 ** dec; // Math.pow(10, dec);
    const scaledAndRounded = Math.round(value * factor);

    return scaledAndRounded / factor;
  }
}
