export class ObjectUtils {
  static isEqual(obj1: unknown, obj2: unknown, seen = new WeakMap()): boolean {
    if (obj1 === obj2) {
      return true; // Handle identical references & primitives.
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false; // Handle non-objects (primitives, null).
    }

    if (seen.has(obj1) || seen.has(obj2)) {
      return seen.get(obj1) === seen.get(obj2); // Handle circular references.
    }

    seen.set(obj1, obj2);

    if (obj1 instanceof Date && obj2 instanceof Date) {
      return obj1.getTime() === obj2.getTime(); // Handle Dates.
    }

    if (obj1 instanceof RegExp && obj2 instanceof RegExp) {
      return obj1.toString() === obj2.toString(); // Handle RegEx.
    }

    if (obj1 instanceof Map && obj2 instanceof Map) {
      if (obj1.size !== obj2.size) {
        return false;
      }

      return [...obj1.entries()].every(([key, val]) =>
        ObjectUtils.isEqual(val, obj2.get(key), seen),
      );
    }

    if (obj1 instanceof Set && obj2 instanceof Set) {
      if (obj1.size !== obj2.size) {
        return false;
      }

      return [...obj1].every((val) => obj2.has(val));
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every(
      (key) =>
        keys2.includes(key) &&
        ObjectUtils.isEqual(
          (obj1 as Record<string, unknown>)[key],
          (obj2 as Record<string, unknown>)[key],
          seen,
        ),
    );
  }

  static getObjectWithKeys<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
    return keys.reduce(
      (acc, curr) => {
        if (keys.includes(curr)) {
          acc[curr] = obj[curr];
        }

        return acc;
      },
      {} as Pick<T, K>,
    );
  }

  static getObjectWithExcludedKeys<T, K extends keyof T>(obj: T, excludedKeys: K[]): Omit<T, K> {
    return Object.keys(obj as object).reduce(
      (acc, curr) => {
        if (!excludedKeys.includes(curr as K)) {
          (acc as T)[curr as keyof T] = obj[curr as keyof T];
        }

        return acc;
      },
      {} as Omit<T, K>,
    );
  }

  static getValue(obj: unknown, path: string, defaultValue: unknown = null): unknown {
    return path
      .split('.')
      .reduce(
        (acc, curr) =>
          acc && typeof acc === 'object' && curr in acc
            ? (acc as Record<string, unknown>)[curr]
            : defaultValue,
        obj,
      );
  }

  static filterObject<T extends Record<string, T[K]>, K extends keyof T>(
    obj: T,
    predicate: (obj: T[K]) => boolean,
  ): Partial<Record<string, T[K]>> {
    return Object.keys(obj).reduce(
      (acc, curr) => {
        if (predicate(obj[curr])) {
          acc[curr] = obj[curr];
        }

        return acc;
      },
      {} as Record<string, T[K]>,
    );
  }
}
