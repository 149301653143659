import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { EnvCodes } from '@indicina/swan-shared/constants/EnvCodes';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

export const i18nexus = {
  initialise: async (envCode: string, targetVersion: number, isReact = false): Promise<void> => {
    const apiKey = 'uwO4mZY_AlyGQZd4nixPnQ';
    const i18nextSharedOptions = {
      defaultNS: 'default',
      fallbackLng: 'en',
      ns: ['default'],
      supportedLngs: AppSettings.i18nexus.supportedLanguages.map((x) => x.code),
    };
    const i18nextHandlersForMissingData = {
      fallbackLng: 'en',
      saveMissing: true,
      saveMissingTo: 'fallback' as const,
      missingKeyHandler: (_lang: readonly string[], _namespace: string, key: string) => {
        console.warn(`Missing translation key [${key}]`);
      },
      missingInterpolationHandler: (text: string, value: string) => {
        console.warn(`Missing interpolation value '${value}' from text '${text}'`);
      },
    };
    const latestTranslationsUrl = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
    const versionedTranslationsUrl = `https://cdn.i18nexus.com/versions/${targetVersion}/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

    const configureLatestTranslations = async (): Promise<void> => {
      await i18n.use(HttpBackend).init({
        ...i18nextSharedOptions,
        backend: {
          loadPath: latestTranslationsUrl,
        },
        // This allows for the highlighting of translation issues.
        // If too many errors are detected, disable the feature and address the issues.
        ...i18nextHandlersForMissingData,
      });
    };

    const configureVersionedTranslations = async (): Promise<void> => {
      await i18n.use(ChainedBackend).init({
        ...i18nextSharedOptions,
        backend: {
          backends: [LocalStorageBackend, HttpBackend],
          backendOptions: [
            {
              defaultVersion: targetVersion,
            },
            {
              loadPath: versionedTranslationsUrl,
            },
          ],
        },
      });
    };

    const i18n = i18next.use(LanguageDetector);

    if (isReact) {
      i18n.use(initReactI18next);
    }

    const latestTranslationEnvironments = [
      // NOTE: Manage env codes when/if required.
      EnvCodes.LOCAL,
      EnvCodes.CI,
      EnvCodes.DEV,
      EnvCodes.QA,
    ];
    const shouldUseLatestTranslations = !envCode || latestTranslationEnvironments.includes(envCode);
    const configureTranslations = shouldUseLatestTranslations
      ? configureLatestTranslations
      : configureVersionedTranslations;

    await configureTranslations();

    if (!isReact) {
      // Attaching 'i18next' to the window scope so that Angular 'language.service' can access it.
      (window as any).i18next = i18n;
    }
  },
};
