export class PerformanceUtils {
  static debounce(callback: () => void, delay = 500) {
    let timeoutId: number | null = null;

    return (...args: []) => {
      // Clear any existing timeout.
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout.
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    };
  }
}
