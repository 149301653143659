import { AssetClassEnum } from '@indicina/swan-shared/enums/AssetClassEnum';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';

interface AssetUtilsByIds {
  isSite: (assetClassId: number) => boolean;
  isSiteHealthArea: (assetClassId: number) => boolean;
}

export class AssetUtils {
  static isDepthMeter(type: string): boolean {
    return type === AssetClassNameEnum.DepthMeter;
  }

  static isSiteIMU(type: string): boolean {
    return type === AssetClassNameEnum.SiteIMU;
  }

  static isSiteHealthArea(type: string): boolean {
    return type === AssetClassNameEnum.SiteHealthArea;
  }

  static isSiteHealthPoint(type: string): boolean {
    return type === AssetClassNameEnum.SiteHealthPoint;
  }

  static isSiteWaterArea(type: string): boolean {
    return type === AssetClassNameEnum.SiteWaterArea;
  }

  static isSoilMoisture(type: string): boolean {
    return type === AssetClassNameEnum.SoilMoisture;
  }

  static isWaterFlowMeter(type: string): boolean {
    return type === AssetClassNameEnum.WaterFlowMeter;
  }

  static isWaterStore(type: string): boolean {
    return type === AssetClassNameEnum.WaterStore;
  }

  static isWeatherStation(type: string): boolean {
    return type === AssetClassNameEnum.WeatherStation;
  }

  static ByAssetIds: AssetUtilsByIds = {
    isSite: (assetClassId: number) => AssetUtils.isSiteByAssetId(assetClassId),
    isSiteHealthArea: (assetClassId: number) => AssetUtils.isSiteHealthAreaByAssetId(assetClassId),
  };

  // ByAssetIds
  private static isSiteByAssetId(assetClassId: number): boolean {
    return assetClassId === AssetClassEnum.Site;
  }

  private static isSiteHealthAreaByAssetId(assetClassId: number): boolean {
    return assetClassId === AssetClassEnum.SiteHealthArea;
  }
}
