import { ObjectUtils } from '@indicina/swan-shared/utils/ObjectUtils';
import { StringComparer } from '@indicina/swan-shared/utils/StringUtils';

type SortOrder = 'asc' | 'desc';

interface ComparerField {
  name: string;
  primerFn?: (input: unknown) => number | boolean;
  sort?: SortOrder;
}

export class ArrayUtils {
  static isArray(items: unknown): boolean {
    return Array.isArray(items);
  }

  static toArray<T>(items: T | T[], fallback: T[] = []): T[] {
    return ArrayUtils.isArray(items)
      ? (items as T[])
      : items
        ? ([items] as T[]).filter((x) => x)
        : fallback;
  }

  static sum(items: number[]): number {
    return items.reduce((acc, curr) => acc + curr, 0);
  }

  static max(items: number[]): number {
    return items.reduce((acc, curr) => Math.max(acc, curr));
  }

  static min(items: number[]): number {
    return items.reduce((a, b) => Math.min(a, b));
  }

  static hasDuplicates<T>(items: T[] | undefined, getValueFn = (item: T) => item): boolean {
    const seen = new Set();

    return (items ?? []).some((item) => {
      const value = getValueFn(item);

      if (seen.has(value)) {
        return true;
      }

      seen.add(value);

      return false;
    });
  }

  static sortByString<T>(
    items: T[] | undefined,
    getValueFn: (item: T) => string,
    sort: SortOrder = 'asc',
    shouldMutate = false,
  ): T[] {
    const tmp = items ?? [];
    const sortedItems = (!shouldMutate ? tmp.slice() : tmp).sort((x: T, y: T) =>
      StringComparer.compareStrings(getValueFn(x), getValueFn(y)),
    );

    return sort === 'asc' ? sortedItems : sortedItems.reverse();
  }

  static sortByStringWithMutation<T>(
    items: T[] | undefined,
    getValueFn: (item: T) => string,
    sort: SortOrder = 'asc',
  ): T[] {
    return ArrayUtils.sortByString(items, getValueFn, sort, true);
  }

  static sortByNumber<T>(
    items: T[] | undefined,
    getNumberFn: (item: T) => number,
    sort: SortOrder = 'asc',
    shouldMutate = false,
  ): T[] {
    const tmp = items ?? [];
    const sortedItems = (!shouldMutate ? tmp.slice() : tmp).sort((x, y) =>
      InternalComparer.compareNumbers(getNumberFn(x), getNumberFn(y)),
    );

    return sort === 'asc' ? sortedItems : sortedItems.reverse();
  }

  static sortByNumberWithMutation<T>(
    items: T[] | undefined,
    getNumberFn: (item: T) => number,
    sort: SortOrder = 'asc',
  ): T[] {
    return ArrayUtils.sortByNumber(items, getNumberFn, sort, true);
  }

  static sortByDate<T>(
    items: T[] | undefined,
    getDateFn: (item: T) => Date,
    sort: SortOrder = 'asc',
    shouldMutate = false,
  ): T[] {
    const tmp = items ?? [];
    // If ascending, then default date is now, so blank dates are at the end.
    // If descending, then default date is epoch, so blank dates are at the end.
    const defaultDate = sort === 'asc' ? new Date() : new Date(0);

    return (!shouldMutate ? tmp.slice() : tmp)
      .slice()
      .sort((x, y) =>
        sort === 'asc'
          ? (getDateFn(x) ?? defaultDate).valueOf() - (getDateFn(y) ?? defaultDate).valueOf()
          : (getDateFn(y) ?? defaultDate).valueOf() - (getDateFn(x) ?? defaultDate).valueOf(),
      );
  }

  static sortByDateWithMutation<T>(
    items: T[] | undefined,
    getDateFn: (item: T) => Date,
    sort: SortOrder = 'asc',
  ): T[] {
    return ArrayUtils.sortByDate(items, getDateFn, sort, true);
  }

  static sortByMultiple<T>(items: T[] | undefined, ...fields: ComparerField[]): T[] {
    return (items ?? []).slice().sort(InternalComparer.getComparePropertiesFn(...fields));
  }

  static sortByMultipleWithMutation<T>(items: T[] | undefined, ...fields: ComparerField[]): T[] {
    return (items ?? []).sort(InternalComparer.getComparePropertiesFn(...fields));
  }

  static sortByCustomComparer<T>(
    items: T[] | undefined,
    customCompareFn: (x: T, y: T) => number,
  ): T[] {
    return (items ?? []).slice().sort(customCompareFn);
  }

  static sortStrings(items: string[] | undefined, sort: SortOrder = 'asc'): string[] {
    return ArrayUtils.sortByString(items, (x) => x, sort);
  }

  static sortNumbers(items: number[] | undefined, sort: SortOrder = 'asc'): number[] {
    return ArrayUtils.sortByNumber(items, (x) => x, sort);
  }

  static sortByValueOrder(array: string[], valueOrder: string[]) {
    // Filter out items from the array that are present in the customOrder
    const customItems = array.filter((item) => valueOrder.includes(item));

    // Sort the custom items using the custom order
    customItems.sort((x, y) => valueOrder.indexOf(x) - valueOrder.indexOf(y));

    // Filter out the rest of the items (items not present in the customOrder)
    const remainingItems = array.filter((item) => !valueOrder.includes(item));

    // Concatenate the sorted custom items with the remaining items
    const sortedArray = [...customItems, ...remainingItems];

    return sortedArray;
  }

  // static getDistinctAndSortedItems<T>(items: T[] | undefined, sort: SortOrder = 'asc'): T[] {
  //   const distinctValues = ArrayUtils.distinct(items);
  //   let sortedValues: any[] | null = null;

  //   if (typeof distinctValues[0] === 'string') {
  //     sortedValues = ArrayUtils.sortStrings(distinctValues as string[], sort);
  //   } else if (typeof distinctValues[0] === 'number') {
  //     sortedValues = ArrayUtils.sortNumbers(distinctValues as number[], sort);
  //   }

  //   return (sortedValues ?? distinctValues) as any as T[];
  // }

  static groupBy<TKey, TItem>(
    items: TItem[] | undefined,
    getKeyFn: (item: TItem) => TKey,
    ignoreKeySorting = false,
    sort: SortOrder = 'asc',
  ): Map<TKey, TItem[]> {
    const map = new Map<TKey, TItem[]>();

    let results = (items ?? []).filter((i) => !!i);

    if (!items?.length) {
      return map;
    }

    if (!ignoreKeySorting) {
      if (typeof getKeyFn(items[0]) === 'string') {
        results = ArrayUtils.sortByString(items, getKeyFn as (item: TItem) => string, sort);
      } else if (typeof getKeyFn(items[0]) === 'number') {
        results = ArrayUtils.sortByNumber(items, getKeyFn as (item: TItem) => number, sort);
      }
    }

    for (const item of items ?? []) {
      const key = getKeyFn(item);
      const collection = map.get(key);

      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    }

    return map;
  }

  static intersect<T>(x: T[], y: T[], getKeyFn: (item: T) => unknown = (item) => item): T[] {
    return (x ?? []).filter((xs) => !!(y ?? []).find((ys) => getKeyFn(ys) === getKeyFn(xs)));
  }

  static equals<T>(x: T[], y: T[], getKeyFn: (item: T) => unknown = (item) => item): boolean {
    return ArrayUtils.intersect(x, y, getKeyFn).length === x?.length && x?.length === y?.length;
  }

  static flatMap<T, U>(array: T[], mapFn: (item: T) => U[]): U[] {
    // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
    return (array ?? []).reduce((acc: U[], curr: T) => [...acc, ...mapFn(curr)], <U[]>[]);
  }

  static distinct<T>(array: T[] | undefined, distinctComparerFn = (x: T, y: T) => x === y): T[] {
    return (array ?? []).reduce<T[]>((acc: T[], curr: T) => {
      if (!acc.some((x) => distinctComparerFn(x, curr))) {
        acc.push(curr);
      }

      return acc;
    }, []);
  }

  static except<T>(
    array1: T[] | undefined,
    array2: T[] | undefined,
    getKeyFn: (item: T) => unknown = (item) => item,
  ): T[] {
    const keysForRemoval = new Set((array2 ?? []).map((x) => getKeyFn(x)));

    return (array1 ?? []).filter((x) => !keysForRemoval.has(getKeyFn(x)));
  }

  // static braid(...arrays: any[]) {
  //   const braided = [] as any[];

  //   for (let i = 0; i < Math.max(...arrays.map(a => a.length)); i++) {
  //     arrays.forEach((array) => {
  //       if (array[i] !== undefined) {
  //         braided.push(array[i]);
  //       }
  //     });
  //   }

  //   return braided;
  // }

  static range(start: number, stop: number, step = 1) {
    return Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step,
    );
  }

  static commaSeparate(items: number[]): string {
    return items?.reduce((acc, item, index, arr) => {
      const separator = index < arr.length - 1 ? ', ' : '';

      return acc + item + separator;
    }, '');
  }
}

class InternalComparer {
  static compareDefault(x: number | boolean, y: number | boolean): number {
    // eslint-disable-next-line eqeqeq
    if (x === y) {
      return 0;
    }

    return x < y ? -1 : 1;
  }

  static compareNumbers(x: number | null, y: number | null): number {
    if (x === y) {
      return 0;
    }

    if (typeof x !== 'number') {
      // Sort `null` values after anything else.
      return 1;
    }

    if (typeof y !== 'number') {
      return -1;
    }

    return x < y ? -1 : 1;
  }

  /**
   * Gets a comparer to sort on multiple object properties, with an optional `primerFn` and `reverse` direction indicator.
   * Adapted from https://stackoverflow.com/a/6913821/154170
   */
  static getComparePropertiesFn<T>(...fields: ComparerField[]): (x: T, y: T) => number {
    const getFieldComparerFn = (
      primer: ((input: unknown) => number | boolean) | undefined,
      sort: SortOrder = 'asc',
    ): ((x: number | boolean, y: number | boolean) => number) => {
      let cmp = InternalComparer.compareDefault;

      if (primer) {
        cmp = (x, y) => {
          const value1 = primer(x);
          const value2 = primer(y);

          if (typeof value1 === 'number' || typeof value2 === 'number') {
            return InternalComparer.compareNumbers(value1 as number, value2 as number);
          }

          return InternalComparer.compareDefault(value1 as boolean, value2 as boolean);
        };
      }

      if (sort === 'desc') {
        return (x, y) => -1 * cmp(x, y);
      }

      return cmp;
    };

    // Preprocess sorting options.
    const preprocessed: { name: string; comparerFn: (x: unknown, y: unknown) => number }[] = [];

    for (const field of fields) {
      let property: string;
      let comparerFn: (x: unknown, y: unknown) => number;

      if (typeof field === 'string') {
        property = field;
        comparerFn = StringComparer.compareStrings as (x: unknown, y: unknown) => number;
      } else {
        property = field.name;
        comparerFn = getFieldComparerFn(field.primerFn, field.sort) as (
          x: unknown,
          y: unknown,
        ) => number;
      }

      preprocessed.push({
        name: property,
        comparerFn: comparerFn as (x: unknown, y: unknown) => number,
      });
    }

    // Final comparison function.
    return (x, y): number => {
      let name: string;
      let result = 0;

      for (let i = 0; i < fields.length; i++) {
        result = 0;
        name = preprocessed[i].name;

        const value1 = ObjectUtils.getValue(x, name);
        const value2 = ObjectUtils.getValue(y, name);

        result = preprocessed[i].comparerFn(value1, value2);

        if (result !== 0) {
          break;
        }
      }

      return result;
    };
  }
}
